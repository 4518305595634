.brands-container {
  display: flex;
  height: 100%;

  .page-menu-list {
    top: -75px;

    &:after {
      top: -21px;
    }

    .ant-menu-item {
      align-items: center;

      .brand-icon {
        display: flex;
        height: 21px;
        padding: 5px 4px;
        margin: auto 10px;
        border-radius: 6px;
        color: #FFFFFF;
        background-color: #FFA53A;
        font-weight: bold;
        font-size: 10px;
        align-items: center;
      }

      .brand-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 18ch;
      }
    }
  }

  .brand-config-section {
    width: 100%;
    padding: 100px 12% 50px;

    .ant-form-item-row {
      display: block;
    }

    .brand-game-badge-container {
      margin-bottom: 20px;
    }

    .brand-section-label {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 16px;
    }

    .entities-tab-container {
      @import "../forms/content/game-form-lists";

      .entity-form-lists-container {
        position: relative;
        @import "../forms/content/entities-selection";
        @import "../components/atoms/select-all-checkbox";

        .select-all-checkbox {
          top: 0;
          position: relative;
          display: inline-flex;

          >span:last-child {
            margin-top: 5px;
          }
        }

        .ant-checkbox-group {
          display: block;

          .provider-with-games {
            .ant-checkbox-wrapper {
              display: inline-flex;
              margin: 0 15px 0 0;
              padding: 0 5px 5px 5px;

              &:after {
                content: unset;
              }
            }

            .provider-block {
              .left-block {
                display: inline-block;

                &.with-no-img {
                  margin-left: 15px;
                }
              }
            }
          }
        }

        .service-config-section {
          .select-all-checkbox > span:last-child {
            padding-left: 0;
            padding-right: 12px;
          }

          .ant-checkbox-group .ant-checkbox-wrapper-checked span {
            color: $blackOpacity85;
          }

          .service {
            &-checkbox-wrapper {
              display: flex;

              &:not(:last-child){
                margin-bottom: 4px;
              }

              .ant-checkbox-wrapper {
                display: inline-flex;
                margin: 0 12px 0 0;
                padding: 1px 0;

                &:after {
                  content: unset;
                }

                &.checkbox-block:hover {
                  background: transparent;
                }
              }
            }

            &-list {
              padding: 10px 0 25px;
            }

            &-item {
              margin-bottom: 10px;

              &:last-child {
                margin-bottom: 0;
              }
            }

            &-option {
              &-list {
                margin-left: 26px;
              }

              &-item {
                margin-bottom: 4px;

                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }

    .country-list-select-tab,
    .selector-wrapper {
      .ant-select {
        &-selector {
          border-radius: 6px;
        }
      }

      .ant-form-item-label {
        margin-bottom: 5px;
      }

      .currency-locale-block,
      .def-currency-locale-block,
      .currencies-and-languages-block {
        display: flex;
        gap: 20px;

        .ant-form-item {
          width: 100%;
        }
      }

      .locales-and-currencies-map-section {
        display: flex;
        gap: 4%;

        .country-to-locale-map,
        .country-to-currency-map {
          width: 48%;

          &-fields {
            display: flex;
            align-items: center;
            gap: 10px;
            margin: 10px 0;

            .ant-form-item {
              margin: auto 0;
            }

            .ant-row {
              min-width: 105px;
              margin-bottom: 0;
            }

            .btn-remove-field {
              visibility: hidden;
              justify-content: center;
              box-shadow: none;

              &:hover {
                border-color: transparent;
              }
            }

            &:hover {
              .btn-remove-field {
                visibility: initial;
              }
            }
          }
        }

        .btn-add-field {
          display: flex;
        }
      }
    }

    .whitelist-countries {
      &-group {
        .group-label {
          font-weight: bold;
          display: block;
          margin-bottom: 5px;
        }

        .whitelist-country {
          background: $secondary10;
          padding: 7px 10px;
          margin: 0 10px 10px 0;
          border-radius: 19px;
          display: inline-flex;
          align-items: center;

          svg {
            max-width: 20px;
            height: auto;
            margin-right: 5px;
          }
        }
      }
    }

    .main-inputs-group {
      width: 100%;
      display: inline-flex;
      gap: 24px;

      .ant-form-item {
        width: 100%;
      }
    }

    .icon-color-section {
      position: relative;

      .color-picker {
        position: relative;
        width: auto;
        display: inline-block;
        margin: 0 10px;
        top: 4px;
        z-index: 1;

        .sketch-picker {
          top: -305px;
          left: 17px;
          position: absolute;
        }
      }

      &__label {
        font-weight: 600;
      }

      .ant-form-item {
        display: flex;

        &-label {
          font-weight: normal;

          label:after {
            content: ':';
            position: relative;
            top: -0.5px;
            margin: 0 8px 0 2px;
          }
        }
      }

      .ant-input {
        padding: 4px 11px 4px 35px;
        max-width: unset;
        width: calc(100% - 30px);
      }

      .brand-icon {
        position: absolute;
        right: 0;
        top: 25px;
        color: $white;
        padding: 4px 5px;
        border-radius: 6px;
        font-weight: bold;
        font-size: 10px;
      }
    }
  }

  &__tabs {
    overflow: unset;

    .ant-tabs {
      &-tab {
        &-btn {
          display: flex;
          align-items: center;
          gap: 7px;
          font-weight: 400 !important;
        }
      }
    }
  }

  .timestamp-selector {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 25px;
    margin: 20px 0;

    &__label {
      font-weight: 600;
    }

    .ant-select {
      .ant-select-arrow {
        position: absolute;
        top: 17px;
      }
    }

    .ant-form-item>.ant-row {
      display: block;
    }

    .ant-form-item {
      margin: 0;
      width: 40%;
    }

    .ant-row .ant-input-number {
      width: 100%;
    }

    .ant-select {
      width: 40%;
    }

    .ant-select-selector,
    .ant-row .ant-input-number-input {
      height: 40px;
    }

    .ant-select-selection-item {
      line-height: 36px;
    }

    .ant-select-selector,
    .ant-row .ant-input-number {
      border-radius: 6px;
    }
  }
}
